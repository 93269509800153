import io from 'socket.io-client';

const baseUrl = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:3030';

export const socketService = createSocketService();

socketService.setup();

function createSocketService() {
  let socket = null;

  const socketService = {
    setup() {
      socket = io(baseUrl);
    },
    on(eventName, cb) {
      if (socket) {
        socket.on(eventName, cb);
      }
    },
    off(eventName, cb = null) {
      if (socket) {
        if (cb) {
          socket.off(eventName, cb);
        } else {
          socket.removeAllListeners(eventName);
        }
      }
    },
    emit(eventName, data) {
      if (socket) {
        socket.emit(eventName, data);
      }
    },
    terminate() {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    },
    getSocketId() {
      return socket ? socket.id : null;
    },
  };

  return socketService;
}
