import { useEffect, useState, useRef } from 'react';
import { socketService } from '../services/socket.service';

export const CanvasCmp = ({ isDrawer }) => {
    const canvasRef = useRef();
    const canvasWrapperRef = useRef();
    const [isDrawing, setIsDrawing] = useState(false);
    const [coords, setCoords] = useState({ x: 0, y: 0 });

    // Canvas initialization
    useEffect(() => {
        initCanvas();

        socketService.on('join', ({ drawingCache }) => {
            drawingCache.forEach(line => drawLine(line.xStart, line.yStart, line.xFinish, line.yFinish, false));
        });

        socketService.off('draw');
        socketService.on('draw', ({ xStart, yStart, xFinish, yFinish }) => {
            drawLine(xStart, yStart, xFinish, yFinish, false);
        });

        socketService.off('clear');
        socketService.on('clear', () => {
            initCanvas();
        });

        const resizeListener = () => resizeCanvas();
        window.addEventListener('resize', resizeListener);

        return () => {
            socketService.off('join');
            socketService.off('draw');
            socketService.off('clear');
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const resizeCanvas = () => {
        canvasRef.current.width = canvasWrapperRef.current.offsetWidth;
        canvasRef.current.height = canvasWrapperRef.current.offsetHeight;
    };

    const initCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.strokeStyle = '#000';
        ctx.lineCap = 'round';
        ctx.lineWidth = 5;
    };

    const startDrawing = (e) => {
        e.preventDefault(); // Prevent default behavior to stop scrolling
        setIsDrawing(true);
        setCoords(getRecentCoords(e));
    };

    const getRecentCoords = (e) => {
        const canvas = canvasRef.current;
        const touchEvents = ['touchstart', 'touchmove', 'touchend'];

        if (touchEvents.includes(e.type)) {
            return {
                x: e.changedTouches[0].clientX - canvas.getBoundingClientRect().left,
                y: e.changedTouches[0].clientY - canvas.getBoundingClientRect().top
            };
        } else {
            return {
                x: e.clientX - canvas.getBoundingClientRect().left,
                y: e.clientY - canvas.getBoundingClientRect().top
            };
        }
    };

    const stopDrawing = (e) => {
        if (isDrawing) {
            const newCoords = getRecentCoords(e);
            if (isDrawer) {
                drawLine(newCoords.x, newCoords.y, newCoords.x, newCoords.y, true);
            }
            setIsDrawing(false);
        }
    };

    const draw = (e) => {
        if (isDrawing && isDrawer) {
            e.preventDefault(); // Prevent default behavior to stop scrolling while drawing
            const newCoords = getRecentCoords(e);
            drawLine(coords.x, coords.y, newCoords.x, newCoords.y, true);
            setCoords(newCoords);
        }
    };

    const drawLine = (xStart, yStart, xFinish, yFinish, isEmit) => {
        const ctx = canvasRef.current.getContext('2d');

        ctx.beginPath();
        ctx.moveTo(xStart, yStart); // Start
        ctx.lineTo(xFinish, yFinish); // Finish
        ctx.stroke();

        if (isEmit) {
            socketService.emit('draw', { xStart, yStart, xFinish, yFinish });
        }
    };

    const clear = () => {
        socketService.emit('clear');
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const handleTouchMove = (e) => {
            e.preventDefault(); // Prevent default scrolling during touch
            draw(e);
        };
        const handleMouseMove = (e) => {
            draw(e);
        };

        canvas.addEventListener('touchmove', handleTouchMove, { passive: false });
        canvas.addEventListener('mousemove', handleMouseMove);

        return () => {
            canvas.removeEventListener('touchmove', handleTouchMove);
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDrawing, coords]);

    return (
        <div ref={canvasWrapperRef} className="canvas-wrapper flex column align-center">
            <canvas
                ref={canvasRef}
                className={`rounded ${isDrawer ? 'canvas-enabled' : 'canvas-disabled'}`}
                width={600}
                height={450}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseOut={stopDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={stopDrawing}
            />
            {isDrawer && <button className="secondary-btn" onClick={clear}>Clear</button>}
        </div>
    );
};
